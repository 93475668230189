<template>
  <el-card shadow="hover" class="mallManagement x-card-title" header="创建商城">
    <div slot="header" class="clearfix" style="line-height: 32px;">
      <span>创建商城</span>
      <el-button style="float: right;" size="small" @click="() => { $router.go(-1); }">返回</el-button>
    </div>
    <div class="step">
      <el-steps :active="stepActive" align-center>
        <el-step title="填写基本信息"></el-step>
        <el-step title="选择商城模板"></el-step>
        <el-step title="商城搭建完成"></el-step>
      </el-steps>
    </div>
    <div class="container">
      <div v-show="stepActive == 1" class="form">
        <div class="title">基本信息</div>
        <div class="content">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
            <el-form-item label="商城名称" prop="shop_name">
              <el-input v-model="ruleForm.shop_name"></el-input>
            </el-form-item>

            <!--            <el-form-item-->
            <!--              label="商城类型"-->
            <!--              prop="mall_type"-->
            <!--            >-->
            <!--              <el-radio-group v-model="ruleForm.mall_type">-->
            <!--                <el-radio label="2">现金商城</el-radio>-->
            <!--              </el-radio-group>-->
            <!--            </el-form-item>-->

            <el-form-item label="站点地址" prop="domain_name">
              <el-input v-model="ruleForm.domain_name">
                <template slot="prepend">https://</template>
                <template slot="append">{{ $url }}</template>
              </el-input>
            </el-form-item>

            <el-form-item label="站点负责人" prop="link_name">
              <el-input v-model="ruleForm.link_name"></el-input>
            </el-form-item>

            <el-form-item label="负责人联系方式" prop="link_phone">
              <el-input v-model="ruleForm.link_phone"></el-input>
            </el-form-item>

            <el-form-item label="站点账号" prop="mobile">
              <template>
                <el-input type="text" v-model="ruleForm.mobile"></el-input>
                <el-input type="text" style="position: fixed; bottom: -9999999px"></el-input>
              </template>
            </el-form-item>

            <el-form-item label="站点初始密码" prop="password">
              <el-input type="password" style="position: fixed; bottom: -9999999px"></el-input>
              <el-input type="password" v-model="ruleForm.password"></el-input>
            </el-form-item>

            <el-form-item label="默认地区" prop="region">
              <x-address-select :value.sync="ruleForm.regionValue" />
            </el-form-item>

            <!--            <el-form-item-->
            <!--              label="登陆方式"-->
            <!--              prop="integer"-->
            <!--            >-->
            <!--              <el-radio-group v-model="ruleForm.integer">-->
            <!--                &lt;!&ndash; <el-radio label="1">手机号+密码</el-radio> &ndash;&gt;-->
            <!--                <el-radio label="2">手机号+验证码</el-radio>-->
            <!--              </el-radio-group>-->
            <!--            </el-form-item>-->

            <el-form-item label="站点状态" prop="shop_disable">
              <el-radio-group v-model="ruleForm.shop_disable">
                <el-radio label="OPEN">启用</el-radio>
                <el-radio label="CLOSED">禁用</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="商城备注信息" prop="shop_remark">
              <el-input type="textarea" :rows="2" v-model="ruleForm.shop_remark"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" style="color: #fff" @click="prevStep()">取消
              </el-button>
              <el-button type="primary" style="color: #fff" @click="nextStep()">下一步
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div v-show="stepActive === 2" class="selectTemplate">
        <div class="listTemplate">
          <div :class="ruleForm.template == i ? 'act' : ''" v-for="i in 2" :key="i">
            <div class="img">
              <img src="../../assets/images/home.jpg" alt="" />
            </div>
            <div class="btn">
              <el-button :type="ruleForm.template === i ? 'primary' : 'info'" @click="ruleForm.template = i">选择模板
              </el-button>
            </div>
          </div>
        </div>
        <div class="btns">
          <el-button type="primary" style="color: #fff" @click="prevStep()">上一步
          </el-button>
          <el-button type="primary" style="color: #fff" @click="nextStep()">下一步
          </el-button>
        </div>
      </div>
      <div v-show="stepActive === 3" class="complete">
        <div class="content">
          <div class="content1">
            <div class="left">
              <div class="icon">
                <i class="el-icon-success"></i>
              </div>
              <div class="text">“现金商城”基础配置完成</div>
              <div class="qrCode">
                <div class="img">
                  <img :src="qrcode" />
                </div>
                <div class="txt">扫一扫查看商城效果</div>
              </div>
              <!--              <div class="btn">-->
              <!--                <el-button-->
              <!--                  type="primary"-->
              <!--                  @click="goManage()"-->
              <!--                >进入管理页-->
              <!--                </el-button>-->
              <!--              </div>-->
            </div>
            <div class="right">
              <img src="../../assets/iphone.jpg" />
            </div>
          </div>
        </div>
        <div class="btns">
          <!-- <el-button type="primary" style="color: #fff" @click="prevStep()"
            >上一步</el-button
          > -->
          <el-button type="primary" style="color: #fff" @click="nextStep()">完成
          </el-button>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import md5 from "js-md5";
import * as API_MallManageMent from "@/api/mallManagement";
import {
  validatePhone,
  validateLowerCase,
  validateAlphabetsAndNumber,
} from "@/utils/validate";
import XAddressSelect from "../../components/x-address-select/x-address-select";

const phoneValidator = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入联系方式"));
  }
  if (!validatePhone(value)) {
    callback(new Error("请输入正确的手机号码"));
  }
  callback();
};
const phoneValidator1 = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入站点账号"));
  }
  if (!validatePhone(value)) {
    callback(new Error("请输入正确的手机号码作为站点账号"));
  }
  callback();
};
const accountValidator = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入站点账号"));
  }
  if (
    !validateAlphabetsAndNumber(value) ||
    value.length < 6 ||
    value.length > 20
  ) {
    callback(
      new Error("最少6位，最多20位。只能是数字、字母，字母区分大小写。")
    );
  }
  callback();
};
const lowerCaseValidator = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请补全网址"));
  }
  if (!validateLowerCase(value)) {
    callback(new Error("请输入小写字母"));
  }
  if (value && value.length > 15) {
    callback(new Error("输入的最大长度为15个字符"));
  }
  callback();
};

export default {
  name: "cash-shop-edit",
  components: { XAddressSelect },
  data () {
    return {
      stepActive: 1,
      qrcode: "",
      ruleForm: {
        shop_name: "",
        mall_type: "2",
        domain_name: "",
        link_name: "",
        link_phone: "",
        mobile: "",
        password: "",
        integer: "2",
        shop_disable: "OPEN",
        template: 1,
        regionValue: undefined,
      },
      rules: {
        // 目前改为了非必填
        // regionValue: [
        //   {
        //     required: true,
        //     validator: (rule, value, callback) => {
        //
        //       if (!value || value.length === 0) {
        //         return callback('请选择默认地址');
        //       }
        //
        //       callback();
        //     },
        //     trigger: "blur",
        //   },
        // ],
        shop_name: [
          {
            required: true,
            message: "请输入商城名称",
            trigger: "blur",
          },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
        ],
        domain_name: [
          {
            required: true,
            validator: lowerCaseValidator,
            trigger: "blur",
          },
        ],
        link_name: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入负责人名称"));
              }
              if (!/^[\u4e00-\u9fa5]*$/.test(value)) {
                callback(new Error("请输入正确的负责人名称"));
              }
              callback();
            },
            trigger: "blur",
          },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
        link_phone: [
          {
            required: true,
            validator: phoneValidator,
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            validator: phoneValidator1,
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入站点初始密码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },

  mounted () {
    this.rules.mobile[0].validator = accountValidator
  },
  methods: {
    prevStep () {
      // 上一步操作
      this.stepActive > 1
        ? this.stepActive--
        : this.$router.push({ name: "shopManagement" });
    },
    nextStep () {
      // 下一步操作
      if (this.stepActive <= 3) {
        // 所有表单校验通过  进行验证  执行下一步不
        const data = JSON.parse(JSON.stringify(this.ruleForm));

        if (data.password) {
          data.password = md5(data.password);
        }

        if (data.regionValue) {
          data.region = data.regionValue[data.regionValue.length - 1];
          delete data.regionValue;
        }

        if (this.stepActive == 1) {
          this.$refs.ruleForm.validate((valid) => {
            if (valid) {
              API_MallManageMent.addChildShopVerification(data).then((res) => {
                this.stepActive++;
              });
            }
          });
        } else if (this.stepActive == 2) {
          //把后台需要的个性化域名参数传递过去
          let urls = this.$url;
          data.out_domain = urls.substr(1);
          console.log(data);
          API_MallManageMent.addChildShop(data)
            .then((res) => {
              this.qrcode = res.qrcode;
              this.stepActive++;
            })
            .catch(() => {
              this.stepActive--;
            });
        } else if (this.stepActive == 3) {
          this.$router.push({ name: "shopManagement", query: { combo: 0 } });
        }
      }
    },
  },
  goManage () {
    // 进入管理页
    alert("进入管理页面");
  },
};
</script>

<style lang="scss" scoped>
.container {
  .step {
    width: 80%;
    margin: 0 auto;
  }

  .form {
    width: 80%;
    padding: 20px 30px;
    margin: 20px auto;

    .title {
      font-weight: bolder;
      font-size: 22px;
      text-align: center;
    }

    .content {
      width: 60%;
      margin: 0 auto;
      padding: 10px 0;

      /deep/ .el-form-item {
        margin-bottom: 15px;
      }
    }
  }

  .selectTemplate {
    width: 80%;
    border: 1px solid #ccc;
    padding: 20px 30px;
    margin: 20px auto;

    .listTemplate {
      display: -webkit-flex;
      justify-content: space-between;

      &>div {
        width: 48%;

        .img {
          width: 100%;
          height: 400px;
          overflow: hidden;

          img {
            width: 100%;
            height: auto;
          }
        }

        .btn {
          margin-top: 10px;
          text-align: center;
        }

        &.act .btn button {
          color: #fff;
        }
      }
    }

    .btns {
      margin-top: 20px;
      width: 100%;
      text-align: center;
    }
  }

  .complete {
    width: 80%;
    border: 1px solid #ccc;
    padding: 20px 30px;
    margin: 20px auto;

    .content {
      display: flex;
      justify-content: center;
      margin: 0 10px;

      .content1 {
        display: -webkit-flex;
        justify-content: space-between;
      }

      .left {
        width: 48%;
        display: -webkit-flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 40px;

        .icon {
          color: #52c41a;
          font-size: 100px;
          display: -webkit-flex;
          justify-content: center;
        }

        .text {
          font-weight: bolder;
          color: #333333;
          font-size: 18px;
          text-align: center;
        }

        .qrCode {
          display: -webkit-flex;
          flex-direction: column;
          align-items: center;
          margin: 0 auto;

          .img {
            width: 200px;
            height: 200px;
            background: red;
          }

          .txt {
            margin-top: 10px;
            font-weight: bolder;
            color: #333333;
            font-size: 18px;
            text-align: center;
          }
        }

        .btn {
          text-align: center;
        }
      }

      .right {
        width: 175px;
        height: 350px;
        margin-left: 40px;

        /* background: red; */
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .btns {
      margin-top: 20px;
      width: 100%;
      text-align: center;
    }
  }
}
</style>
